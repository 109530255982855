<template>
  <div>
    <!-- S:Bread crumbs -->
    <v-row>
      <v-col sm="12" md="12" lg="12" class="pa-10">
        <v-card elevation="1" class="containerbox">
          <breadcrumbComp class="hidden-sm-and-down" :mainPage="mainPage" :subPage="subPage" :backSlash="backSlash" :pageUrl="pageUrl"
            :showAdd="showAdd" @addFav="addFav()" :refresh="refresh" :resetFunction="resetFunction" />
          <breadcrumbComp class="hidden-md-and-up" :subPage="subPage" :pageUrl="pageUrl" :showAdd="showAdd" @addFav="addFav()" :refresh="refresh"
            :resetFunction="resetFunction" />
          <!-- E:Breadcrumbs -->
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" class="pa-5">
              <v-card outlined color="transparent" class="pa-0 ma-0 containerbox">
                <v-card-text class="pb-0 pt-0">
                  <v-form ref="userListForm" v-model="isFormValid" class="pa-2 pr-5" lazy-validation>
                    <v-row class="pt-5">
                      <!-- TO add Email -->
                      <v-col cols="12" sm="12" md="12" lg="6" class="px-5 py-0">
                        <v-text-field color="primarytext" v-model="email" label="Email" @keydown.enter="getPageData" outlined clearable dense
                          :rules="max100Rule" persistent-placeholder>
                        </v-text-field>
                      </v-col>
                      <!--  To select Roles Can be Multiple -->
                      <v-col cols="12" sm="12" md="12" lg="5" class="pl-5 pr-1 py-0">
                        <v-autocomplete color="primarytext" attach outlined class="ma-0" v-model="roles" dense chips small-chips :items="roleList"
                          label="Role" item-text="RoleName" item-value="RoleId" required multiple persistent-placeholder>
                          <template #selection="{ item }">
                            <v-chip color="primary" class="pa-1 ma-1" label>{{ item.RoleName }}</v-chip>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="1" class="pr-5 pl-0 py-0 float-right">
                        <v-switch class="ma-2 d-inline-block" @change="getPageData" v-model="isActive" :label="isActive ? 'Active' : 'Inactive'" inset color="primarytext"
                          hide-details></v-switch>
                      </v-col>
                    </v-row>
                    <v-row>
                      <!-- TO add First Name -->
                      <v-col cols="12" sm="12" md="12" lg="6" class="px-5 py-0">
                        <v-text-field color="primarytext" v-model="firstName" label="First Name" @keydown.enter="getPageData" outlined clearable dense
                          :rules="max50Rule" persistent-placeholder>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="5" class="pl-5 pr-1 py-0">
                        <!-- To add Last Name -->
                        <v-text-field color="primarytext" v-model="lastName" label="Last Name" @keydown.enter="getPageData" outlined clearable dense
                          :rules="max50Rule" persistent-placeholder>
                        </v-text-field>
                      </v-col>
                      <!-- Locked -->
                      <v-col cols="12" sm="12" md="12" lg="1" class="pr-5 pl-0 py-0 float-right">
                        <v-switch class="ma-2 d-inline-block " @change="getPageData" v-model="locked" label="Lock" inset color="primarytext"
                          hide-details></v-switch>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="hidden-sm-and-down px-5 py-0" cols="12" sm="12" md="12" lg="4"></v-col>

                      <v-col cols=" 12" sm="12" md="12" lg="2" class="px-5 py-0">
                        <v-btn color="primarytext" outlined class="ma-2 btn-style float-md-right float-lg-right float-xl-right" elevation="1"
                          @click="getPageData()" :disabled="!isFormValid">
                          <v-icon dark left> mdi-magnify </v-icon>Search Users
                        </v-btn>
                      </v-col>
                      <!--  Search Button and cancel -->
                      <v-col cols="12" sm="12" md="12" lg="6" class="px-5 py-0">
                        <v-btn color="secondary" outlined dense class="ma-2 btn-style " elevation="1" @click="cancel()">
                          <v-icon dark left> mdi-magnify-close </v-icon>Clear Search
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!-- Table -->
    <v-col cols="12" sm="12" md="12" lg="12" class="pa-7 pt-0">
      <v-card outlined class="containerbox">
        <v-card-text class="pb-0 pt-0">
          <v-row>
            <v-col cols="12" sm="6" md="6" lg="9" class="px-5 pt-9 pl-8">
              <span color="primarytext">
                No.of Records: {{ totalRecords }}
              </span>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="3" class="ma-0 pt-6 pr-8">
              <!-- Button to add user -->
              <v-btn v-if="actionSetter('AddUser')" class="mx-0 my-2  btn-style float-right px-2 success" elevation="1" outlined color="cWhite"
                @click="userAdd()">
                <v-icon dark left> mdi-plus </v-icon>New User
              </v-btn>
              <export-excel v-if="actionSetter('ExportUser')" class="btn btn-default" :fetch="exportExcel" :fields="json_fields" worksheet="Sheet1"
                :name="excelName" :before-generate="startDownload" :before-finish="finishDownload">
                <!-- Button to export -->
                <v-btn :disabled="exportDisable" class="ma-2 btn-style float-right" elevation="1" outlined color="secondary" @click="fileName()">
                  <v-icon dark left> mdi-file-export </v-icon>Export
                </v-btn>
              </export-excel>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="12" lg="12" class="pa-0 pl-8 pr-8 pb-5">
              <v-data-table :headers="headers" :items="userList" hide-default-footer item-key="id">

                <template v-slot:item.Fname="{ item }">
                  <a @click="userDetails(item)" :disabled="!actionSetter('ViewUserDetails')"
                    v-bind:class="($vuetify.theme.dark) ? 'anchorDark' : 'anchorlight'">
                    <v-text>{{ item.Fname + ' ' + item.Lname }}</v-text>
                  </a>
                </template>
                <template v-slot:item.Roles="{ item }">
                  <div v-html="formatedValues(item.Roles,4)"></div>
                </template>


                <template v-slot:item.Status="{ item }">
                  <span class="active" v-if="item.Status == '1'">Active</span>
                  <span class="inactive" v-else>Inactive</span>
                </template>

                <template v-slot:item.Locked="{ item }">
                  <span class="inactive" v-if="item.Locked">Locked</span>
                </template>

                <template v-slot:item.Email="{ item }">
                  <span>{{ item.Email.split('@')[0] }}<br />
                    {{ '@' + item.Email.split('@')[1] }}</span>
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2" @click="userDetails(item)" :disabled="!actionSetter('ViewUserDetails')">
                    mdi-eye</v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <!-- Pagination-->
          <v-row>
            <v-col cols="12" lg="4" md="4" sm="4" class="pt-0 px-5"></v-col>
            <v-col cols="12" lg="5" md="5" sm="5" class="pt-0 px-5">
              <v-pagination v-model="page" :length="pageCount" :total-visible="7" @input="pageNumber()">
              </v-pagination>
            </v-col>
            <v-col cols="12" lg="3" md="3" sm="3" class="pt-2 px-5"> </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <exportLimit v-show="exportDialog" :exportDialog="exportDialog" :totalRecords="totalRecords" @exportData="exportData()" />

  </div>
</template>

<script>
import Utility from '../../shared/utility';
import Vue from 'vue';
import excel from 'vue-excel-export';
import breadcrumbComp from '../common/breadcrumb-comp.vue';
import EncryptUtility from '../utility/js/encrypt-utility';
import AddFavourite from "../utility/js/addFav";
import exportLimit from '../common/export-limit.vue';
Vue.use(excel);
export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm._backRoute = from.path
    })
  },
  data: () => ({
    rules: {
      required: (value) => !!value || 'Input field is required.',
    },
    max50Rule: [
      (v) => (v || '').length <= 50 || "Max Length of 50 character"
    ],
    max20Rule: [
      (v) => (v || '').length <= 20 || "Max Length of 20 character"
    ],
    max100Rule: [
      (v) => (v || '').length <= 100 || "Max Length of 100 character"
    ],
    max10Rule: [
      (v) => (v || '').length <= 10 || "Max Length of 10 character"
    ],
    isFormValid: false,
    email: '',
    roles: [],
    favouriteList: [],
    showAdd: false,
    export50kData: false,
    exportDialog: false,
    exportFlag: true,
    firstName: '',
    phone: '',
    lastName: '',
    exportDisable: false,
    locked:false,
    isActive: true,
    roleList: [],
    totalRecords: 0,
    actionList: [],
    page: 1,
    userId: EncryptUtility.localStorageDecrypt('userID'),
    pageCount: 1,
    excelName: '',
    refresh: true,
    headers: [
      {
        text: 'Name',
        align: 'start',
        value: 'Fname',
        class: 'primary customwhite--text',
      },
      { text: 'Email', value: 'Email', class: 'primary customwhite--text',align: 'start' },
      { text: 'Phone', value: 'Phone', class: 'primary customwhite--text',align: 'start' },
      { text: 'Roles', value: 'Roles', class: 'primary customwhite--text',align: 'start' },
      { text: 'Status', value: 'Status', class: 'primary customwhite--text',align: 'start' },
      { text: 'Locked', value: 'Locked', class: 'primary customwhite--text' ,align: 'start'},
      {
        text: 'Installer Name',
        value: 'InstallName',
        class: 'primary customwhite--text',
      },
      {
        text: 'Actions',
        value: 'actions',
        class: 'primary customwhite--text',
        sortable: false,
      },
    ],
    json_fields: {
      
      Name: 'Name',
      Email: 'Email',
      Phone: {
        field: 'Phone',
        format: 'string',
        callback: (value) => {
          return `="${value}"`
        }
      },
      Roles: 'Roles',
      Status: 'Status',
      Locked: 'Locked',
      'Installer Name': 'InstallName',
      
    },
    json_meta: [
      [
        {
          key: 'charset',
          value: 'utf-8',
        },
      ],
    ],
    userList: [],
    dark: JSON.parse(EncryptUtility.localStorageDecrypt('theme')),
    disabled: false,
    backSlash: true,
    route: '',
    urlArray: [],
    searchObject: "",
  }),
  async created() {
    this.route = this.$route.path.replace('/', '');
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList
    this.mainPage = data.mainPage
    this.favouriteList = data.favouriteList
    this.pageUrl = data.pageUrl
    this.subPage = data.subPage
    this.showAdd = data.showAdd
  },
  mounted() {
    if (this._backRoute !== undefined) {
      if (this._backRoute.split("/")[1] == "app-user-add" || this._backRoute.split("/")[1] == ("app-user-details")) {
        this.searchObject = EncryptUtility.localStorageDecrypt('userSearchObject')
        let object = JSON.parse(this.searchObject)
        this.firstName = object.Fname
        this.lastName = object.Lname
        this.email = object.Email
        this.isActive = object.Isactive == "1" ? true : false
        this.locked=Boolean(object.locked)
        let roleList = object.Roles.split(',')
        roleList == null || roleList == "" || roleList == null ? this.roles = [] :
          roleList.forEach(element => {
            this.roles.push(parseInt(element))
          });
      }
    } else if (this._backRoute == "/") {
      this.searchObject = "";
    }
    this.getRoleList();
    this.getPageData();
  },
  methods: {
    // Export Data
    async exportData() {
      this.export50kData = true;
      this.exportDialog = false;
    },
    formatedValues(item){
      return Utility.insertLineBreaks(item,4,",",true)
    },
    // export Data
    async exportExcel() {
      let status = this.isActive ? 1 : 0;
      this.exportDialog = false
      this.searchObject = JSON.stringify({
        Fname: this.firstName,
        Lname: this.lastName,
        Email: this.email,
        Isactive: status,
        Roles: this.roles.toString() !== undefined || this.roles.toString() !== null ||
          this.roles.toString().length > 0 ? this.roles.toString() : '',
        SearchType: 2
      });
      if (this.totalRecords <= 15000 ) {
        let pageData = {
          PageNumber: this.page,
          SearchParam: this.searchObject,
          UserId: this.userId
        }
        this.excelName =
          'userList_' +
          new Date()
            .toISOString()
            .replace('T', '_')
            .replace('Z', '')
            .replace('.', '')
            .replaceAll('-', '_') +
          '.xls';
        const response = await this.axios.post('up/user_list', pageData);
        let responseData = JSON.parse(response.data.body.message).Data;
        responseData.forEach(obj => {
          obj.Name = obj.Fname + ' ' + obj.Lname
          obj.Status = obj.Status == "1" ? "Active" : "Inactive"
          obj.Locked = obj.Locked == false ? "Not Locked" : "Locked"
        })

        
        this.export50kData = false

        return responseData
      }
      else {
        this.exportDialog = true
        this.finishDownload()
        return true
      }

    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
    },
    // end the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
    },
    //To check whether user has access to that action on not
    actionSetter(action) {
      if (this.actionList !== null && this.actionList.length > 0) {
        let filter = this.actionList.filter(
          (entry) => entry.SystemName === action,
        );
        return filter !== undefined && filter !== null && filter.length == 1
          ? true
          : false;
      } else return false;
    },
    //to get The Page number
    pageNumber() {
      this.getPageData();
    },
    //To get the list of roles
    getRoleList() {
      this.roleData = [];
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      this.axios
        .get('/ap/role_list?UserId=' + this.userId)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            let newArr = responseData.filter((list) => list.Status == 'Active');
            this.roleList = newArr;
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            var Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          var Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    //To get the list of pages
    getPageData() {
      this.exportDialog = false
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      let status = this.isActive ? 1 : 0;
      this.searchObject = JSON.stringify({
        Fname: this.firstName,
        Lname: this.lastName,
        Email: this.email,
        Isactive: status,
        Roles: this.roles.toString() !== undefined || this.roles.toString() !== null ||
          this.roles.toString().length > 0 ? this.roles.toString() : '',
        SearchType: 1,
        locked: this.locked ? 1 : 0
      });
      let pageData = {
        PageNumber: this.page,
        SearchParam: this.searchObject,
        UserId: this.userId
      };
      this.axios
        .post('up/user_list', pageData)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = JSON.parse(response.data.body.message);
            this.pageCount = responseData.TotalPages;
            this.totalRecords = responseData.TotalRecords;
            this.userList = responseData.Data;
            this.exportDisable = false
            EncryptUtility.localStorageEncrypt('userSearchObject', this.searchObject)
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            this.exportDisable = true
            this.totalRecords = 0
            this.userList = []
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          this.userList = [];
          this.exportDisable = true;
          this.totalRecords = 0;
          this.pageCount = 1;
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    // file name for exported excel
    fileName() {
      if (this.totalRecords <= 15000 || this.export50kData)
        this.exportFlag = true
      this.excelName =
        'userList_' +
        new Date()
          .toISOString()
          .replace('T', '_')
          .replace('Z', '')
          .replace('.', '')
          .replaceAll('-', '_') +
        '.xls';
    },
    //to reset the form
    cancel() {
      this.email = "";
      this.roles = "";
      this.firstName = "";
      this.phone = "";
      this.lastName = "";
      this.isActive = true;
      this.userList = [];
      this.exportDisable = true;
      this.locked=false;
      this.totalRecords = 0;
      this.pageCount = 1;
      this.page=1
      EncryptUtility.localStorageEncrypt('userSearchObject', "")
    },
    // to redirect the user into a new update page  encrypted id
    userDetails(item) {
      this.email = "";
      this.roles = "";
      this.firstName = "";
      this.phone = "";
      this.lastName = "";
      this.isActive = true;
      let id = item.userid;
      let userDel = false;
      this.$router.push({
        name: 'appUserDetails',
        params: {
          id: btoa(id.toString()),
          editUser: btoa(this.actionSetter('EditUser').toString()),
          pwdResetByAdmin: btoa(this.actionSetter('ResetUserPasswordByAdmin').toString()),
          deleteUser: btoa(userDel.toString())
        },
      });
    },
    // to redirect the user into a new update page
    userAdd() {
      this.email = "";
      this.roles = "";
      this.firstName = "";
      this.phone = "";
      this.lastName = "";
      this.isActive = true;
      this.$router.push('/app-user-add');
    },
    addFav() {
      this.showAdd = !this.showAdd
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route)
    },
    //Reset function
    resetFunction() {
      this.email = "";
      this.roles = "";
      this.firstName = "";
      this.phone = "";
      this.lastName = "";
      this.isActive = true;
      this.getPageData();
      EncryptUtility.localStorageEncrypt('userSearchObject', "")
    },
  },
  components: {
    breadcrumbComp,
    exportLimit,
  },
};
</script>

<style scoped>
.active {
  background-color: #2ed8b6;
  border-radius: 5px;
  padding: 5px;
  color: white;
}

.inactive {
  background-color: #ff5370;
  border-radius: 5px;
  padding: 5px;
  color: white;
}
</style>
